import React from 'react'
import SEO from '../components/seo.js'
import { graphql } from 'gatsby'

const QAItem = props => {
    return (
        <div className='prose'>
            <h3>{props.question}</h3>
            <p>{props.answer}</p>
        </div>
    )
}

export default props => {
    const faqs = props.data.allFaqJson.nodes
    return (
        <div className='max-w-5xl mx-auto px-2 sm:px-4'>
            <SEO title='Home' />
            <div className='border-b border-black py-5'>
                <h1 className='font-black text-xl sm:text-3xl'>Frequently Asked Questions</h1>
            </div>
            <ul className='grid grid-cols-1 md:grid-cols-2 py-4 px-2 gap-4'>
                {faqs.map((item, i) => (
                    <li key={i}>
                        <QAItem {...item} />
                    </li>
                ))}
            </ul>
        </div>
    )
}
export const query = graphql`
    query AllFaqs {
        allFaqJson {
            nodes {
                answer
                question
            }
        }
    }
`
